<style scoped lang="scss" src="./style.scss" />
<script lang="ts" src="./script.ts" />

<template>
    <div v-for="(item, index) in requiredFields.filter((x) => x.requiredField != RequiredField[RequiredField.TermsAndConditions])" v-bind:key="index" class="col-12 mb-3">
        <span>{{ getTranslation("Input" + item.requiredField + "Label") }} <span class="text-danger">*</span></span>

        <label v-if="item.requiredField == RequiredField[RequiredField.BirthDate]" class="r-font-size-200 w-100">
            <input
                class="r-input"
                :class="{
                    check: birthDateIsValid,
                    'r-input-error': birthDateInputError,
                }"
                v-maska:[birthDateMaskaOptions]
                data-maska="##/##/####"
                v-bind:placeholder="getBirthDatePlaceholder()"
                inputmode="numeric" />
            <span class="hint" :class="{ 'hint-error': birthDateInputError }">{{ birthDateHintMessage }}</span>
        </label>

        <label v-else-if="item.requiredField == RequiredField[RequiredField.CompanyName]" class="r-font-size-200 w-100">
            <input class="r-input" :placeholder="item.requiredField" :oninput="(e: InputEvent) => handleInput(e, item)" />
            <span class="hint" :class="{ 'hint-error': false }">{{ companyNameHintMessage }}</span>
        </label>

        <label v-else-if="item.requiredField == RequiredField[RequiredField.IdentificationNumber]" class="r-font-size-200 w-100">
            <input
                class="r-input"
                :class="{
                    'check-identification-number': identificationNumberIsValid === true && getIdentificationNumber()?.length == 11,
                    check: identificationNumberIsValid === true,
                    'r-input-error': identificationNumberIsValid === false,
                }"
                v-maska:[identificationNumberMaskaOptions]
                :data-maska="getIbanDynamicMask()"
                data-maska-tokens="Z:[a-zA-Z0-9]"
                placeholder="00000000000" />
            <span class="hint" :class="{ 'hint-error': identificationNumberIsValid === false }">{{ identificationNumberHintMessage }}</span>
        </label>

        <label v-else-if="item.requiredField == RequiredField[RequiredField.IbanNumber]" class="r-font-size-200 w-100 position-relative">
            <input
                class="r-input"
                :class="{
                    'r-input-error': ibanNumberIsValid === false,
                }"
                v-maska:[ibanNumberMaskaOptions]
                :data-maska="getIbanDynamicMask()"
                :placeholder="getIbanDynamicPlaceholder()" />
            <span class="hint" :class="{ 'hint-error': ibanNumberIsValid === false }">{{ ibanNumberHintMessage }}</span>
            <div v-if="ibanNumberIsValid === true" class="check-iban"></div>
        </label>

        <label v-else-if="item.requiredField == RequiredField[RequiredField.MobilePhone]" class="r-font-size-200 w-100">
            <input
                @keypress="handleMobilePhoneInput"
                @keyup="handleMobilePhoneChange"
                @paste.prevent
                maxlength="16"
                class="r-input"
                :class="{
                    'r-input-error': mobilePhoneIsValid === false,
                }"
                placeholder="00000000" />
            <span class="hint" :class="{ 'hint-error': mobilePhoneIsValid === false }">{{ mobilePhoneHintMessage }}</span>
        </label>

        <label v-else class="r-font-size-200 w-100">
            <input class="r-input" :placeholder="item.requiredField" />
        </label>
    </div>
</template>
