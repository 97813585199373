<script lang="ts" src="./script.ts" />

<template>
    <RequiredFieldsComponent
        v-if="componentLoaded"
        :requiredFields="requiredFields"
        :profileTrackingId="profileTrackingId"
        :country="country"
        :language="language"
        @onIdempotencyKeyUpdate="onIdempotencyKeyUpdate"
        @onRetryAttemptCountUpdate="onRetryAttemptCountUpdate"
        @onCanContinueUpdate="onCanContinueUpdate"></RequiredFieldsComponent>
    <ModalComponent :isFullModal="true">
        <iframe :src="iframUrl" height="100%" width="100%"></iframe>
    </ModalComponent>
</template>
