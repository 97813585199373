<style scoped lang="scss" src="./style.scss" />
<script lang="ts" src="./script.ts" />

<template>
    <div v-on:click="handleClick" id="modal-content" :class="{ full: isFullModal, default: !isFullModal }">
        <div class="modal-inner">
            <slot></slot>
        </div>
    </div>
</template>
