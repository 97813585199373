import { checkFetch, normalizeJsonToLowerCase, translation } from "@/utils/common";
import defaultLogo from "@/assets/riverty-secondary-badge.svg";
import { LegalInfo, PaymentMethod, TextResourceList } from "@/entities";
import { getTextResources } from "tests/entities";
import { post } from "@/utils/httpRequest";
import { defineComponent } from "vue";
import { PaymentType } from "@/enums";

export default defineComponent({
    name: "PaymentTypeComponent",
    props: {
        language: {
            type: String,
            required: true,
        },
        paymentMethod: {
            type: PaymentMethod,
            required: true,
        },
    },
    data: function () {
        return {
            textResourceList: new TextResourceList(),
            logo: "",
        };
    },
    mounted() {
        this.getData();
        this.checkAndSetLogo(this.paymentMethod.legalInfo);
    },
    methods: {
        async getData(fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null) {
            this.textResourceList = (await this.getTextResources(this.language, fetchFromTest, httpsAgent)) as TextResourceList;
        },
        async getTextResources(languageCode: string, fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null): Promise<TextResourceList | getTextResources> {
            const sysnameList = ["PaymentTitle"];

            const response = await post(
                "text-resources/list",
                {
                    languageCode,
                    sysnameList,
                },
                fetchFromTest,
                httpsAgent
            );

            if (!fetchFromTest) return response;

            return { obj: response, list: sysnameList };
        },
        getTranslation(sysName: string, replace: string[] = []): string {
            return translation(this.textResourceList.textResourceItems, sysName, replace);
        },
        getPayInXObj() {
            if (this.paymentMethod.additionalData) {
                return JSON.parse(normalizeJsonToLowerCase(this.paymentMethod.additionalData));
            }
        },
        payInXType() {
            return PaymentType[PaymentType.PayinX];
        },
        installmentType() {
            return PaymentType[PaymentType.Installment];
        },
        async checkAndSetLogo(legalInfo: LegalInfo) {
            const { logo, secondaryLogo } = legalInfo;

            if (await checkFetch(logo)) {
                this.logo = logo;
            } else if (await checkFetch(secondaryLogo)) {
                this.logo = secondaryLogo;
            } else {
                this.logo = defaultLogo;
            }
        },
    },
});
