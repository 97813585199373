<style scoped lang="scss" src="./style.scss" />
<script lang="ts" src="./script.ts" />

<template>
    <div class="page">
        <div class="container h-100 d-flex justify-content-center align-items-center">
            <div class="row">
                <div class="col-12 text-center">
                    <h1>404 Error - Page Not Found</h1>
                    <p>We're sorry, but the page you requested could not be found. Please check the URL and try again.</p>
                </div>
            </div>
        </div>
    </div>
</template>
