import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "r-font-size-200 w-100"
}
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = {
  key: 1,
  class: "r-font-size-200 w-100"
}
const _hoisted_4 = ["placeholder", "oninput"]
const _hoisted_5 = {
  key: 2,
  class: "r-font-size-200 w-100"
}
const _hoisted_6 = ["data-maska"]
const _hoisted_7 = {
  key: 3,
  class: "r-font-size-200 w-100 position-relative"
}
const _hoisted_8 = ["data-maska", "placeholder"]
const _hoisted_9 = {
  key: 0,
  class: "check-iban"
}
const _hoisted_10 = {
  key: 4,
  class: "r-font-size-200 w-100"
}
const _hoisted_11 = {
  key: 5,
  class: "r-font-size-200 w-100"
}
const _hoisted_12 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.requiredFields.filter((x) => x.requiredField != _ctx.RequiredField[_ctx.RequiredField.TermsAndConditions]), (item, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: index,
      class: "col-12 mb-3"
    }, [
      _createElementVNode("span", null, [
        _createTextVNode(_toDisplayString(_ctx.getTranslation("Input" + item.requiredField + "Label")) + " ", 1),
        _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-danger" }, "*", -1))
      ]),
      (item.requiredField == _ctx.RequiredField[_ctx.RequiredField.BirthDate])
        ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
            _withDirectives(_createElementVNode("input", {
              class: _normalizeClass(["r-input", {
                    check: _ctx.birthDateIsValid,
                    'r-input-error': _ctx.birthDateInputError,
                }]),
              "data-maska": "##/##/####",
              placeholder: _ctx.getBirthDatePlaceholder(),
              inputmode: "numeric"
            }, null, 10, _hoisted_2), [
              [_directive_maska, void 0, _ctx.birthDateMaskaOptions]
            ]),
            _createElementVNode("span", {
              class: _normalizeClass(["hint", { 'hint-error': _ctx.birthDateInputError }])
            }, _toDisplayString(_ctx.birthDateHintMessage), 3)
          ]))
        : (item.requiredField == _ctx.RequiredField[_ctx.RequiredField.CompanyName])
          ? (_openBlock(), _createElementBlock("label", _hoisted_3, [
              _createElementVNode("input", {
                class: "r-input",
                placeholder: item.requiredField,
                oninput: (e) => _ctx.handleInput(e, item)
              }, null, 8, _hoisted_4),
              _createElementVNode("span", {
                class: _normalizeClass(["hint", { 'hint-error': false }])
              }, _toDisplayString(_ctx.companyNameHintMessage), 1)
            ]))
          : (item.requiredField == _ctx.RequiredField[_ctx.RequiredField.IdentificationNumber])
            ? (_openBlock(), _createElementBlock("label", _hoisted_5, [
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass(["r-input", {
                    'check-identification-number': _ctx.identificationNumberIsValid === true && _ctx.getIdentificationNumber()?.length == 11,
                    check: _ctx.identificationNumberIsValid === true,
                    'r-input-error': _ctx.identificationNumberIsValid === false,
                }]),
                  "data-maska": _ctx.getIbanDynamicMask(),
                  "data-maska-tokens": "Z:[a-zA-Z0-9]",
                  placeholder: "00000000000"
                }, null, 10, _hoisted_6), [
                  [_directive_maska, void 0, _ctx.identificationNumberMaskaOptions]
                ]),
                _createElementVNode("span", {
                  class: _normalizeClass(["hint", { 'hint-error': _ctx.identificationNumberIsValid === false }])
                }, _toDisplayString(_ctx.identificationNumberHintMessage), 3)
              ]))
            : (item.requiredField == _ctx.RequiredField[_ctx.RequiredField.IbanNumber])
              ? (_openBlock(), _createElementBlock("label", _hoisted_7, [
                  _withDirectives(_createElementVNode("input", {
                    class: _normalizeClass(["r-input", {
                    'r-input-error': _ctx.ibanNumberIsValid === false,
                }]),
                    "data-maska": _ctx.getIbanDynamicMask(),
                    placeholder: _ctx.getIbanDynamicPlaceholder()
                  }, null, 10, _hoisted_8), [
                    [_directive_maska, void 0, _ctx.ibanNumberMaskaOptions]
                  ]),
                  _createElementVNode("span", {
                    class: _normalizeClass(["hint", { 'hint-error': _ctx.ibanNumberIsValid === false }])
                  }, _toDisplayString(_ctx.ibanNumberHintMessage), 3),
                  (_ctx.ibanNumberIsValid === true)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9))
                    : _createCommentVNode("", true)
                ]))
              : (item.requiredField == _ctx.RequiredField[_ctx.RequiredField.MobilePhone])
                ? (_openBlock(), _createElementBlock("label", _hoisted_10, [
                    _createElementVNode("input", {
                      onKeypress: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMobilePhoneInput && _ctx.handleMobilePhoneInput(...args))),
                      onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMobilePhoneChange && _ctx.handleMobilePhoneChange(...args))),
                      onPaste: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
                      maxlength: "16",
                      class: _normalizeClass(["r-input", {
                    'r-input-error': _ctx.mobilePhoneIsValid === false,
                }]),
                      placeholder: "00000000"
                    }, null, 34),
                    _createElementVNode("span", {
                      class: _normalizeClass(["hint", { 'hint-error': _ctx.mobilePhoneIsValid === false }])
                    }, _toDisplayString(_ctx.mobilePhoneHintMessage), 3)
                  ]))
                : (_openBlock(), _createElementBlock("label", _hoisted_11, [
                    _createElementVNode("input", {
                      class: "r-input",
                      placeholder: item.requiredField
                    }, null, 8, _hoisted_12)
                  ]))
    ]))
  }), 128))
}