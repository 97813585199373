<style scoped lang="scss" src="./style.scss" />
<script lang="ts" src="./script.ts" />

<template>
    <div class="page">
        <header>
            <div class="container">
                <div class="row px-2 px-sm-0">
                    <div class="col-12 px-sm-0 py-4">
                        <img class="r-icon-size-l mb-2" alt="Riverty logo" src="https://cdn.riverty.design/logo/riverty-logomark-green.svg" />
                        <p class="r-font-family-heading r-font-size-600 r-font-weight-light mt-2">{{ getTranslation("CheckoutTitle") }}</p>
                    </div>
                </div>
            </div>
        </header>
        <main class="mb-5">
            <div class="container">
                <div v-if="data.legalInfoRequiredFieldsPerPayments?.length > 1" class="row">
                    <div class="col-12 col-lg-8 px-0 d-flex justify-content-end">
                        <r-button variant="blank" mode="normal" type="button" icon="arrow-left" v-on:click="handleBackToList">{{ getTranslation("BackToListButton") }}</r-button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg me-lg-3 order-2 order-lg-1">
                        <div class="row bg-white mt-3 mt-lg-0 px-2 py-4">
                            <div class="col-12">
                                <p class="r-font-size-500 mb-3">{{ getTranslation("PaymentTitle") }}</p>
                                <div class="row">
                                    <div class="col-auto" v-show="logo">
                                        <img height="56" width="90" :src="logo" alt="Riverty`s logo" />
                                    </div>
                                    <div class="col ps-0 pt-1">
                                        <p class="r-font-size-400 r-font-weight-semibold">{{ data.orderDetails.paymentMethod.title }}</p>
                                        <p class="r-font-size-300">{{ data.orderDetails.paymentMethod.method }}</p>
                                    </div>
                                </div>
                                <PayInXComponent v-if="data.orderDetails.paymentMethod.additionalData && data.orderDetails.paymentMethod.type == payInXType()" :payInX="getPayInXObj()" :getTranslation="getTranslation" />
                                <FixedInstallmentsComponent
                                    v-if="data.orderDetails.paymentMethod.additionalData && data.orderDetails.paymentMethod.type == installmentType()"
                                    ref="fixedInstallmentsComponent"
                                    :paymentMethod="data.orderDetails.paymentMethod"
                                    :getTranslation="getTranslation"></FixedInstallmentsComponent>
                            </div>
                        </div>
                        <div v-if="data.orderDetails.requiredFields.length > 0" class="row bg-white mt-3 px-2 pt-4 pb-2">
                            <div class="col-12">
                                <p class="r-font-family-heading r-font-size-600 r-font-weight-light mb-3">{{ getTranslation("VerifyTitle") }}</p>
                                <p class="r-font-size-400 mb-4">{{ getTranslation("VerifyDescription") }}</p>
                            </div>
                            <div v-for="(item, index) in requiredFields.filter((x) => x.requiredField != RequiredField[RequiredField.TermsAndConditions])" v-bind:key="index" class="col-12 col-lg-7 mb-3">
                                <span>{{ getTranslation("Input" + item.requiredField + "Label") }} <span class="text-danger">*</span></span>

                                <label v-if="item.requiredField == RequiredField[RequiredField.BirthDate]" class="r-font-size-200 w-100">
                                    <input
                                        class="r-input"
                                        :class="{
                                            check: birthDateIsValid,
                                            'r-input-error': birthDateInputError,
                                        }"
                                        v-maska:[birthDateMaskaOptions]
                                        data-maska="##/##/####"
                                        v-bind:placeholder="getBirthDatePlaceholder()"
                                        inputmode="numeric" />
                                    <span class="hint" :class="{ 'hint-error': birthDateInputError }">{{ birthDateHintMessage }}</span>
                                </label>

                                <label v-else-if="item.requiredField == RequiredField[RequiredField.CompanyName]" class="r-font-size-200 w-100">
                                    <input class="r-input" :placeholder="item.requiredField" :oninput="(e: InputEvent) => handleInput(e, item)" />
                                    <span class="hint" :class="{ 'hint-error': false }">{{ companyNameHintMessage }}</span>
                                </label>

                                <label v-else-if="item.requiredField == RequiredField[RequiredField.IdentificationNumber]" class="r-font-size-200 w-100">
                                    <input
                                        class="r-input"
                                        :class="{
                                            'check-identification-number': identificationNumberIsValid === true && getIdentificationNumber()?.length == 11,
                                            check: identificationNumberIsValid === true,
                                            'r-input-error': identificationNumberIsValid === false,
                                        }"
                                        v-maska:[identificationNumberMaskaOptions]
                                        :data-maska="getIbanDynamicMask()"
                                        data-maska-tokens="Z:[a-zA-Z0-9]"
                                        placeholder="00000000000" />
                                    <span class="hint" :class="{ 'hint-error': identificationNumberIsValid === false }">{{ identificationNumberHintMessage }}</span>
                                </label>

                                <label v-else-if="item.requiredField == RequiredField[RequiredField.IbanNumber]" class="r-font-size-200 w-100 position-relative">
                                    <input
                                        class="r-input"
                                        :class="{
                                            'r-input-error': ibanNumberIsValid === false,
                                        }"
                                        v-maska:[ibanNumberMaskaOptions]
                                        :data-maska="getIbanDynamicMask()"
                                        :placeholder="getIbanDynamicPlaceholder()" />
                                    <span class="hint" :class="{ 'hint-error': ibanNumberIsValid === false }">{{ ibanNumberHintMessage }}</span>
                                    <div v-if="ibanNumberIsValid === true" class="check-iban"></div>
                                </label>

                                <label v-else-if="item.requiredField == RequiredField[RequiredField.MobilePhone]" class="r-font-size-200 w-100">
                                    <input
                                        @keypress="handleMobilePhoneInput"
                                        @keyup="handleMobilePhoneChange"
                                        @paste.prevent
                                        maxlength="16"
                                        class="r-input"
                                        :class="{
                                            'r-input-error': mobilePhoneIsValid === false,
                                        }" />
                                    <span class="hint" :class="{ 'hint-error': mobilePhoneIsValid === false }">{{ mobilePhoneHintMessage }}</span>
                                </label>

                                <label v-else class="r-font-size-200 w-100">
                                    <input class="r-input" :placeholder="item.requiredField" />
                                </label>
                            </div>
                        </div>
                        <div class="row bg-white mt-3 px-2 py-4">
                            <div class="col-12">
                                <p class="r-font-family-heading r-font-size-600 r-font-weight-light mb-3">{{ getTranslation("TermsAndConditionsTitle") }}</p>
                            </div>
                            <div v-if="data?.orderDetails?.paymentMethod?.legalInfo?.codeOfConduct" class="col-12 mb-3">
                                <p class="r-font-size-200">
                                    <span v-html="data?.orderDetails?.paymentMethod?.legalInfo?.codeOfConduct"></span>
                                </p>
                            </div>
                            <div
                                class="col-12"
                                :class="{
                                    'mt-2': getIsRequiredCheckbox(),
                                }">
                                <r-checkbox id="termsCheck" v-show="getIsRequiredCheckbox()" v-on:click="handleChecked" />

                                <div v-show="getIsRequiredCheckbox()" class="row checkbox-info" v-on:click="handleLabelChecked">
                                    <div class="col-12">
                                        <p class="r-font-size-200">{{ getTranslation("TermsAndConditions") }}</p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="r-font-size-200 r-color-grey-charcoal-300">
                                            <span v-html="data?.orderDetails?.paymentMethod?.legalInfo?.text"></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div v-show="!getIsRequiredCheckbox()" class="col-12 mb-4">
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class="r-font-size-200 r-color-grey-charcoal-300">
                                            <span v-html="data?.orderDetails?.paymentMethod?.legalInfo?.text"></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-center text-lg-start col-lg-auto">
                                <r-button class="w-100 mb-2" variant="primary" expanded="" :disabled="!canContinue" v-on:click="handleContinue">
                                    {{ continueButtonTranslation() }}
                                </r-button>
                                <br />
                                <r-button class="w-100" variant="text" href="#modal-content" v-on:click="handleCancel">
                                    {{ getTranslation("CancelPayment") }}
                                </r-button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 order-1 order-lg-2">
                        <div class="row bg-white px-2 py-4">
                            <div class="col-12">
                                <p class="r-font-size-600 r-font-weight-semibold mb-4">
                                    {{ data.orderDetails.merchantName }}
                                </p>
                                <p class="r-font-size-600 r-font-weight-semibold">
                                    {{ currency(data.orderDetails.orderDetail.totalAmount) }}
                                </p>
                                <r-accordion>
                                    <r-accordion-item :header="getTranslation('OrderDetailsTitle')">
                                        <div class="row" v-for="(item, index) in data.orderDetails.orderDetail.items" v-bind:key="index">
                                            <div class="col-12">
                                                <div class="row h-100 align-items-center">
                                                    <div class="col">
                                                        <p>
                                                            {{ item.quantity }}x
                                                            {{ item.name }}
                                                        </p>
                                                        <p>
                                                            {{ item.description }}
                                                        </p>
                                                    </div>
                                                    <div class="col-auto">
                                                        <p>
                                                            {{ currency(item.grossUnitPrice) }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr class="m-0 pb-4" />
                                            </div>
                                        </div>
                                        <div v-if="data.orderDetails.provider == Provider[Provider.LightspeedCSeries]" class="row">
                                            <div class="col-12">
                                                <div class="row h-100 align-items-center">
                                                    <div class="col">
                                                        <p>
                                                            {{ getTranslation("DeliveryFee") }}
                                                        </p>
                                                    </div>
                                                    <div class="col-auto">
                                                        <p>
                                                            {{ currency(data.orderDetails.shippingGrossPrice) }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr class="m-0 pb-4" />
                                            </div>
                                        </div>
                                        <div v-if="showAddresses()" class="row mt-3">
                                            <div class="col-12">
                                                <p class="r-font-weight-semibold">{{ getTranslation("ShippingAddress") }}</p>
                                                <p>{{ data.orderDetails.shippingCustomer.firstName }} {{ data.orderDetails.shippingCustomer.lastName }}</p>
                                                <p>{{ data.orderDetails.shippingCustomer.address.street }} {{ data.orderDetails.shippingCustomer.address.streetNumber }}</p>
                                                <p>{{ data.orderDetails.shippingCustomer.address.postalCode }} {{ data.orderDetails.shippingCustomer.address.countryCode }}</p>
                                                <p>{{ data.orderDetails.shippingCustomer.address.postalPlace }}</p>
                                            </div>
                                            <div class="col-12 mt-3">
                                                <p class="r-font-weight-semibold">{{ getTranslation("BillingAddress") }}</p>
                                                <p>{{ data.orderDetails.billingCustomer.firstName }} {{ data.orderDetails.billingCustomer.lastName }}</p>
                                                <p>{{ data.orderDetails.billingCustomer.address.street }} {{ data.orderDetails.billingCustomer.address.streetNumber }}</p>
                                                <p>{{ data.orderDetails.billingCustomer.address.postalCode }} {{ data.orderDetails.billingCustomer.address.countryCode }}</p>
                                                <p>{{ data.orderDetails.billingCustomer.address.postalPlace }}</p>
                                            </div>
                                        </div>
                                    </r-accordion-item>
                                </r-accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <footer>
            <div class="container">
                <div class="row px-2 px-sm-0">
                    <div class="col-12 px-sm-0 pb-3 pt-5 mt-5 pt-lg-3 mt-lg-0">
                        <p class="r-font-size-200">{{ getTranslation("Powered") }}</p>
                        <img alt="Riverty logo" width="143" src="https://cdn.riverty.design/logo/riverty-logo-green.svg" />
                    </div>
                </div>
            </div>
        </footer>
    </div>
    <ModalComponent>
        <div v-if="!showErrorPaymentMessage" class="container p-0">
            <div class="row pb-3">
                <div class="col-12">
                    <p class="r-font-family-heading r-font-size-600 r-font-weight-light mt-3 mb-4">{{ getTranslation("TitleCancelation") }}</p>
                    <p class="r-font-size-400 mb-4 pb-3" v-html="getTranslation('DescriptionCancelation', [data.orderDetails.merchantName])"></p>
                </div>
                <div class="col-12 text-center text-lg-start col-lg-auto">
                    <r-button class="w-100" variant="primary" expanded="" v-on:click="handleConfirmCancelation">
                        {{ getTranslation("ConfirmCancelation") }}
                    </r-button>
                    <br />
                    <r-button variant="text" v-on:click="handleContinueWith">
                        {{ getTranslation("ContinueWith") }}
                    </r-button>
                </div>
            </div>
        </div>
        <div v-else-if="showErrorPaymentMessage && !errorPaymentMessage" class="container p-0">
            <div class="row pb-3">
                <div class="col-12">
                    <img src="../../assets/exclamation_mark.svg" />

                    <p class="r-font-family-heading r-font-size-600 r-font-weight-light my-4">{{ getTranslation("PaymentErrorTitle") }}</p>
                    <p class="r-font-size-500 mb-4">{{ getTranslation("PaymentErrorSubTitle") }}</p>
                    <p class="r-font-size-400 mb-4 pb-3" v-html="getTranslation('PaymentErrorDescription')"></p>

                    <r-button variant="primary" expanded="" v-on:click="handleContinueWith">
                        {{ getTranslation("ReturnTo", [data.orderDetails.merchantName ?? getTranslation("GenericReturnToMerchant")]) }}
                    </r-button>
                </div>
            </div>
        </div>
        <div v-else class="container p-0">
            <div class="row pb-3">
                <div class="col-12">
                    <p class="r-font-family-heading r-font-size-600 r-font-weight-light my-4">{{ getTranslation("PaymentCustomErrorTitle") }}</p>
                    <p class="r-font-size-400 mb-4 pb-3" v-html="getTranslation('PaymentCustomErrorSubTitle', [errorPaymentMessage])"></p>

                    <r-button variant="primary" expanded="" v-on:click="handleContinueWith">
                        {{ getTranslation("ReturnTo", [data.orderDetails.merchantName ?? getTranslation("GenericReturnToMerchant")]) }}
                    </r-button>
                </div>
            </div>
        </div>
    </ModalComponent>
</template>
