import { ModalComponent, PaymentTypeComponent, RequiredFieldsComponent } from "@/components";
import { AuthorizeRequest, PaymentMethod, RequiredFieldItem } from "@/entities";
import { createApp, defineComponent } from "vue";
import { post } from "@/utils/httpRequest";
import { RequiredField } from "@/enums";

interface Init {
    orderNumber: string;
    merchantUrl: string;
    webhookUrl: string;
    apiKey: string;
    requiredFields: Array<string>;
    conversationLanguage: string;
    countryCode: string;
}

interface Authorize {
    data: AuthorizeRequest;
    orderKey: string;
}

export default defineComponent({
    name: "EmbedPayView",
    components: {
        RequiredFieldsComponent,
        ModalComponent,
        PaymentTypeComponent,
    },
    data: function () {
        return {
            apiKey: "",
            profileTrackingId: "",
            country: "",
            language: "",
            requiredFields: [] as Array<RequiredFieldItem>,
            element: null as unknown as Element,
            componentLoaded: false,
            iframUrl: "",
            idempotencyKey: "",
            retryAttemptCount: 0,
            canContinue: false,
            hasForm: true,
        };
    },
    mounted() {
        // eslint-disable-next-line
        const vueRef = this;

        class HostedCheckoutPayElement extends HTMLElement {
            init = vueRef.init;
            authorize = vueRef.authorize;

            constructor() {
                super();
                const form = document.querySelector("form");
                if (form == null) {
                    vueRef.hasForm = false;
                    throw new Error("To be able to use 'r-hosted-checkout-pay' element, you need to have a form element in your page.");
                }
            }
        }
        customElements.define("r-hosted-checkout-pay", HostedCheckoutPayElement);
    },
    methods: {
        async init(init: Init | undefined) {
            window.location.href = "#";

            if (!init || !this.hasForm) return;

            this.requiredFields = [];
            init.requiredFields.forEach((requiredField) => {
                this.requiredFields.push(new RequiredFieldItem(requiredField));
            });
            this.apiKey = init.apiKey;

            const response = await post(`embed/init`, init);

            if (response.status == 200) {
                this.profileTrackingId = response.profileTrackingId;
                this.country = response.country;
                this.language = response.language;

                this.componentLoaded = true;
                this.loadPaymentTypeComponent(response.paymentMethod);
            }

            return response;
        },
        loadPaymentTypeComponent(paymentMethod: PaymentMethod) {
            const form = document.querySelector("form");
            if (form && form.parentNode) {
                const paymentTypeComponentInstance = document.createElement("r-hosted-checkout-payment-type");
                createApp(PaymentTypeComponent, { language: this.language, paymentMethod }).mount(paymentTypeComponentInstance);
                form.parentNode.insertBefore(paymentTypeComponentInstance, form);
            }
        },
        async authorize(authorize: Authorize | undefined) {
            if (!authorize || !this.canContinue) return;
            this.checkAndSetRequiredFields(authorize.data);
            const response = await post(`embed/authorize/${authorize.orderKey}/${this.idempotencyKey}/${this.retryAttemptCount}`, authorize.data);

            if (response.status != 200 && response.isScaRequired) {
                window.location.href = "#modal-content";
                document.body.style.overflow = "hidden";
                this.iframUrl = response.urlRedirect;
            }

            return response;
        },
        checkAndSetRequiredFields(data: AuthorizeRequest) {
            this.requiredFields.forEach((requiredField) => {
                if (requiredField.requiredField === RequiredField[RequiredField.BirthDate]) {
                    data.customer.birthDate = requiredField.value;
                } else if (requiredField.requiredField === RequiredField[RequiredField.MobilePhone]) {
                    data.customer.mobilePhone = requiredField.value;
                }
            });
        },
        onIdempotencyKeyUpdate(idempotencyKey: string) {
            this.idempotencyKey = idempotencyKey;
        },
        onRetryAttemptCountUpdate(retryAttemptCount: number) {
            this.retryAttemptCount = retryAttemptCount;
        },
        onCanContinueUpdate(canContinue: boolean) {
            this.canContinue = canContinue;
        },
    },
});
