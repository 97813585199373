class AuthorizeRequest {
    merchantUrl = "";
    payment = new Payment();
    customer = new Customer();
    order = new Order();
}

class Payment {
    type = "";
}

class Customer {
    identificationNumber = "";
    salutation = "";
    firstName = "";
    lastName = "";
    email = "";
    customerCategory = "";
    address = new Address();
    birthDate = "";
    mobilePhone = "";
}

class Address {
    street = "";
    streetNumber = "";
    postalCode = "";
    postalPlace = "";
    countryCode = "";
}

class Order {
    number = "";
    totalGrossAmount = 0;
    totalNetAmount = 0;
    currency = "";
    items = Array<Item>();
}

class Item {
    productId = "";
    description = "";
    grossUnitPrice = 0;
    netUnitPrice = 0;
    quantity = 0;
    vatPercent = 0;
    vatAmount = 0;
    imageUrl = "";
}

export { AuthorizeRequest };
