import { defineCustomElements } from "@riverty/web-components/loader";
import { EmbedPayView, EmbedPaymentMethodsView } from "./views";
import { Telemetry } from "./utils/telemetry";
import { createApp } from "vue";
import { vMaska } from "maska";
import router from "./router";
import App from "./App.vue";

defineCustomElements(window);
Telemetry.init();

createApp(App).use(router).directive("maska", vMaska).mount("app");

createApp(EmbedPayView).directive("maska", vMaska).mount("r-hosted-checkout-pay");

createApp(EmbedPaymentMethodsView).directive("maska", vMaska).mount("r-hosted-checkout-payment-methods");
