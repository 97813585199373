import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RequiredFieldsComponent = _resolveComponent("RequiredFieldsComponent")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.componentLoaded)
      ? (_openBlock(), _createBlock(_component_RequiredFieldsComponent, {
          key: 0,
          requiredFields: _ctx.requiredFields,
          profileTrackingId: _ctx.profileTrackingId,
          country: _ctx.country,
          language: _ctx.language,
          onOnIdempotencyKeyUpdate: _ctx.onIdempotencyKeyUpdate,
          onOnRetryAttemptCountUpdate: _ctx.onRetryAttemptCountUpdate,
          onOnCanContinueUpdate: _ctx.onCanContinueUpdate
        }, null, 8, ["requiredFields", "profileTrackingId", "country", "language", "onOnIdempotencyKeyUpdate", "onOnRetryAttemptCountUpdate", "onOnCanContinueUpdate"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ModalComponent, { isFullModal: true }, {
      default: _withCtx(() => [
        _createElementVNode("iframe", {
          src: _ctx.iframUrl,
          height: "100%",
          width: "100%"
        }, null, 8, _hoisted_1)
      ]),
      _: 1
    })
  ], 64))
}