<style scoped lang="scss" src="./style.scss" />
<script lang="ts" src="./script.ts" />

<template>
    <div class="row">
        <div class="col-12 p-4 pb-0">
            <div class="row mb-3">
                <div class="col-12">
                    <div class="pb-2" v-for="(item, index) in installments" v-bind:key="index">
                        <r-radio-button :value="item.installmentProfileNumber" name="installmentRadios" :checked="item == selectedInstallmentProfile" v-on:click="onChecked(item)">
                            <p class="r-font-weight-semibold">{{ getTranslation("PayInInstallments", [item.numberOfInstallments, currency(item.installmentAmount)]) }}</p>
                        </r-radio-button>
                    </div>
                </div>
            </div>
            <Transition>
                <div v-show="selectedInstallmentProfile">
                    <div class="row pb-1">
                        <div class="col-12 py-2 r-bg-white-haze-200">
                            <div class="row align-items-center mb-2">
                                <div class="col">
                                    <p>{{ getTranslation("OrderAmount") }}</p>
                                </div>
                                <div class="col-auto">
                                    <p>
                                        {{ currency(selectedInstallmentProfile?.basketAmount ?? 0) }}
                                    </p>
                                </div>
                            </div>
                            <div class="row align-items-center mb-2">
                                <div class="col">
                                    <p>{{ getTranslation("InterestPA", [selectedInstallmentProfile?.interestRate]) }}</p>
                                </div>
                                <div class="col-auto">
                                    <p>
                                        {{ currency(selectedInstallmentProfile?.totalInterestAmount ?? 0) }}
                                    </p>
                                </div>
                            </div>
                            <hr class="m-0" />

                            <div class="row align-items-center pb-4">
                                <div class="col">
                                    <p>{{ getTranslation("TotalAmountDue") }}</p>
                                </div>
                                <div class="col-auto">
                                    <p>
                                        {{ currency(selectedInstallmentProfile?.totalAmount ?? 0) }}
                                    </p>
                                </div>
                            </div>

                            <div class="row align-items-center mb-2">
                                <div class="col">
                                    <p>{{ getTranslation("NumberOfInstallments") }}</p>
                                </div>
                                <div class="col-auto">
                                    <p>
                                        {{ selectedInstallmentProfile?.numberOfInstallments }}
                                    </p>
                                </div>
                            </div>
                            <hr class="m-0" />

                            <div class="row align-items-center pb-4">
                                <div class="col">
                                    <p class="r-font-weight-semibold">{{ getTranslation("InstallmentAmount") }}</p>
                                </div>
                                <div class="col-auto">
                                    <p class="r-font-weight-semibold">
                                        {{ currency(selectedInstallmentProfile?.installmentAmount ?? 0) }}
                                    </p>
                                </div>
                            </div>

                            <p class="mb-4">
                                {{ getTranslation("InterestInformation", [selectedInstallmentProfile?.interestRate, selectedInstallmentProfile?.effectiveAnnualPercentageRate]) }}
                            </p>
                            <p class="mb-4">{{ getTranslation("LastInstallmentAmountDue", [currency(selectedInstallmentProfile?.lastInstallmentAmount ?? 0)]) }}</p>
                            <p
                                class="mb-4"
                                v-show="paymentMethod.legalInfo.bgb507Url && paymentMethod.legalInfo.secciUrl"
                                v-html="getTranslation('MandatoryInformationLinks', [paymentMethod.legalInfo.bgb507Url, paymentMethod.legalInfo.secciUrl])"></p>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
    </div>
</template>
