import { CountryCode, LanguageCode, RequiredField } from "@/enums";
import { ProfileTrackingIdConfig, RequiredFieldItem, TextResourceList } from "@/entities";
import { getTextResources } from "tests/entities";
import { defineComponent, PropType } from "vue";
import { post } from "@/utils/httpRequest";
import { MaskaDetail } from "maska";
import { v4 as uuidv4 } from "uuid";

export default defineComponent({
    name: "RequiredFieldsComponent",
    props: {
        requiredFields: {
            type: Array as PropType<Array<RequiredFieldItem>>,
            required: true,
        },
        profileTrackingId: {
            type: String,
            required: true,
        },
        country: {
            type: String,
            required: true,
        },
        language: {
            type: String,
            required: true,
        },
    },
    emits: ["onIdempotencyKeyUpdate", "onRetryAttemptCountUpdate", "onCanContinueUpdate"],
    data: function () {
        return {
            RequiredField,
            textResourceList: new TextResourceList(),

            birthDateIsValid: false,
            birthDateInputError: false,
            birthDateMaskaOptions: {
                onMaska: (detail: MaskaDetail) => (detail.completed ? this.handleBirthDateComplete(detail) : null),
                postProcess: (val: string) => this.handleBirthDateValidation(val),
            },

            identificationNumberIsValid: null as unknown as boolean,
            identificationNumberHintMessage: "",
            identificationNumberMaskaOptions: {
                onMaska: (detail: MaskaDetail) => (detail.completed ? this.handleIdentificationNumberComplete(detail) : null),
                postProcess: (val: string) => this.handleIdentificationValidation(val),
            },

            ibanNumberIsValid: null as unknown as boolean,
            ibanNumberMaskaOptions: {
                onMaska: (detail: MaskaDetail) => (detail.completed ? this.handleIbanNumberComplete(detail) : null),
                postProcess: (val: string) => this.handleIbanValidation(val),
            },

            mobilePhoneIsValid: null as unknown as boolean,

            birthDateHintMessage: "",
            ibanNumberHintMessage: "",
            mobilePhoneHintMessage: "",
            companyNameHintMessage: "",

            ipAddress: "0.0.0.0",
            sessionId: "",

            oldRequiredFields: Array<RequiredFieldItem>(),
            orderKey: "",

            // emits values
            idempotencyKey: "",
            retryAttemptCount: 0,
            canContinue: false,
        };
    },
    mounted() {
        this.getData();
        this.getIpAddress();
    },
    methods: {
        async getData(fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null) {
            this.textResourceList = (await this.getTextResources(this.language, fetchFromTest, httpsAgent)) as TextResourceList;

            this.birthDateHintMessage = this.getTranslation("InputBirthDateHint");
            this.companyNameHintMessage = this.getTranslation("InputCompanyNameHint");
            this.identificationNumberHintMessage = this.getTranslation("InputIdentificationNumberHint");
            this.ibanNumberHintMessage = this.getTranslation("InputIbanNumberHint");
            this.mobilePhoneHintMessage = this.getTranslation("InputMobilePhoneHint");

            this.profileTracking();

            // searching for idempotencyKey and retryAttemptCount
            this.idempotencyKey = localStorage.getItem(`idempotencyKey-${this.orderKey}`) ?? this.getOrSetIdempotencyKey();
            this.retryAttemptCount = parseInt(localStorage.getItem(`retryAttemptCount-${this.orderKey}`) ?? this.getOrSetRetryAttemptCount());

            this.$emit("onIdempotencyKeyUpdate", this.idempotencyKey);
            this.$emit("onRetryAttemptCountUpdate", this.retryAttemptCount);
        },
        async getTextResources(languageCode: string, fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null): Promise<TextResourceList | getTextResources> {
            const sysnameList = [
                "InputMobilePhoneLabel",
                "InputMobilePhoneHint",
                "InputCompanyNameLabel",
                "InputCompanyNameHint",
                "InputIdentificationNumberLabel",
                "InputIdentificationNumberHint",
                "InputIbanNumberLabel",
                "InputIbanNumberHint",
                "InputSSNNumberLabel",
                "InputBirthDateLabel",
                "InputBirthDateHint",
                "WrongBirthDate",
            ];

            const response = await post(
                "text-resources/list",
                {
                    languageCode,
                    sysnameList,
                },
                fetchFromTest,
                httpsAgent
            );

            if (!fetchFromTest) return response;

            return { obj: response, list: sysnameList };
        },
        getTranslation(sysName: string, replace: string[] = []): string {
            let value = this.textResourceList?.textResourceItems.find((x) => x.sysName == sysName)?.value;

            replace.forEach((item: string, index: number) => {
                value = value?.replace(`{${index}}`, item);
            });

            return value ?? "";
        },
        handleInput(e: InputEvent, item: RequiredFieldItem) {
            item.value = (e.target as HTMLInputElement).value;
            this.canContinue = this.requiredFields.filter((x) => x.value == "").length === 0;

            this.$emit("onCanContinueUpdate", this.canContinue);
        },
        handleBirthDateValidation(val: string) {
            this.birthDateHintMessage = this.getTranslation("InputBirthDateHint");
            this.birthDateInputError = false;
            this.canContinue = false;
            this.birthDateIsValid = false;

            this.dateAndAgeValidationReturningBirthDate(val);

            this.$emit("onCanContinueUpdate", this.canContinue);

            return val;
        },
        handleBirthDateComplete(detail: MaskaDetail) {
            const birthDate = this.dateAndAgeValidationReturningBirthDate(detail.masked);
            const birthDateRequiredField = this.requiredFields.find((x) => x.requiredField == RequiredField[RequiredField.BirthDate]);

            if (!this.birthDateInputError && birthDateRequiredField) {
                this.birthDateIsValid = true;
                birthDateRequiredField.value = birthDate;
                this.canContinue = this.requiredFields.filter((x) => x.value == "").length === 0;

                this.$emit("onCanContinueUpdate", this.canContinue);
            }
        },
        dateAndAgeValidationReturningBirthDate(date: string) {
            const tempVal = date.replaceAll("/", "");
            const day = Number.parseInt(tempVal.substring(0, 2));
            const month = Number.parseInt(tempVal.substring(2, 4));
            const year = Number.parseInt(tempVal.substring(4, 8));

            if ((day > 31 || month > 12 || year > new Date().getFullYear() || year <= 1900) && date.length == 10) {
                this.birthDateInputError = true;
                this.birthDateHintMessage = this.getTranslation("WrongBirthDate");
            }

            const birthDate = `${year}-${month > 9 ? month : "0" + month}-${day > 9 ? day : "0" + day}`;
            this.ageValidation(birthDate);

            return birthDate;
        },
        ageValidation(date: string) {
            if (date.length == 10) {
                const birthDateTime = new Date(date).getTime();
                const currentTime = new Date().getTime();
                const difference = currentTime - birthDateTime;
                const age = difference / (1000 * 60 * 60 * 24 * 365);

                if (age < 18) {
                    this.birthDateInputError = true;
                    this.birthDateHintMessage = this.getTranslation("MinimumAge");
                }
            }
        },
        handleIdentificationValidation(val: string) {
            this.identificationNumberHintMessage = this.getTranslation("InputIdentificationNumberHint");
            this.canContinue = false;
            this.identificationNumberIsValid = false;

            this.$emit("onCanContinueUpdate", this.canContinue);

            if (val.length) {
                switch (this.country) {
                    case CountryCode[CountryCode.SE]:
                    case CountryCode[CountryCode.DK]:
                        this.identificationNumberIsValid = val.length === 10;
                        break;
                    default:
                        this.identificationNumberIsValid = val.length === 11;
                        break;
                }
            }
            return val;
        },
        handleIdentificationNumberComplete(detail: MaskaDetail) {
            const requiredField = this.requiredFields.find((x) => x.requiredField == RequiredField[RequiredField.IdentificationNumber]);

            if (this.identificationNumberIsValid && requiredField) {
                requiredField.value = detail.masked;
                this.canContinue = this.requiredFields.filter((x) => x.value == "").length === 0;

                this.$emit("onCanContinueUpdate", this.canContinue);
            }
        },
        handleIbanValidation(val: string) {
            this.ibanNumberHintMessage = this.getTranslation("InputIbanNumberHint");
            this.canContinue = false;
            this.ibanNumberIsValid = false;

            this.$emit("onCanContinueUpdate", this.canContinue);

            if (val.length) {
                switch (this.country) {
                    case CountryCode[CountryCode.AT]:
                        this.ibanNumberIsValid = val.length === 24;
                        break;
                    case CountryCode[CountryCode.BE]:
                    case CountryCode[CountryCode.NO]:
                        this.ibanNumberIsValid = val.length === 19;
                        break;
                    case CountryCode[CountryCode.CH]:
                        this.ibanNumberIsValid = val.length === 26;
                        break;
                    case CountryCode[CountryCode.DE]:
                        this.ibanNumberIsValid = val.length === 27;
                        break;
                    case CountryCode[CountryCode.SE]:
                        this.ibanNumberIsValid = val.length === 29;
                        break;
                    default:
                        this.ibanNumberIsValid = val.length === 22;
                        break;
                }
            }
            return val;
        },
        handleIbanNumberComplete(detail: MaskaDetail) {
            const requiredField = this.requiredFields.find((x) => x.requiredField == RequiredField[RequiredField.IbanNumber]);

            if (this.ibanNumberIsValid && requiredField) {
                this.ibanNumberIsValid = true;
                requiredField.value = detail.masked;
                this.canContinue = this.requiredFields.filter((x) => x.value == "").length === 0;

                this.$emit("onCanContinueUpdate", this.canContinue);
            }
        },
        handleMobilePhoneInput(e: KeyboardEvent): boolean {
            //only allow characters that can be part of a valid phone nr: numbers, +, -, (, ) and whitespace)
            const regex = /^\+?[0-9\-\s()]*$/;
            const target = e.target as HTMLInputElement;

            //check for allowed characters and make sure there is only one + and it is at the beginning
            if (!regex.test(e.key) || (e.key == "+" && (target.value.includes("+") || (target.selectionStart !== null && target.selectionStart > 0)))) {
                //don't allow that character
                e.preventDefault();
                return false;
            }
            return true;
        },
        handleMobilePhoneChange(e: Event) {
            const target = e.target as HTMLInputElement;
            this.mobilePhoneIsValid = false;

            const str = target.value.replace(/\s/g, ""); //remove whitespaces

            if (str.length) {
                const requiredField = this.requiredFields.find((x) => x.requiredField == RequiredField[RequiredField.MobilePhone]);

                this.mobilePhoneIsValid = str.length > 0 && str.length <= 20;

                if (this.mobilePhoneIsValid && requiredField) {
                    requiredField.value = str;
                }
            }

            this.canContinue = this.mobilePhoneIsValid && this.requiredFields.filter((x) => x.value == "").length === 0;

            this.$emit("onCanContinueUpdate", this.canContinue);
        },
        handleCompanyNameInput(e: InputEvent, item: RequiredFieldItem) {
            item.value = (e.target as HTMLInputElement).value;
            this.canContinue = this.requiredFields.filter((x) => x.value == "").length === 0;

            this.$emit("onCanContinueUpdate", this.canContinue);
        },
        getBirthDatePlaceholder() {
            switch (this.language) {
                case LanguageCode[LanguageCode.NL].toLowerCase():
                    return "DD/MM/JJJJ";
                case LanguageCode[LanguageCode.DE].toLowerCase():
                    return "TT/MM/JJJJ";
                default:
                    return "DD/MM/YYYY";
            }
        },
        getIdentificationNumberDynamicMask() {
            switch (this.country) {
                case CountryCode[CountryCode.SE]:
                case CountryCode[CountryCode.DK]:
                    return "##########";
                case CountryCode[CountryCode.FI]:
                    return "######-###Z";
                default:
                    return "###########";
            }
        },
        getIdentificationNumber() {
            const requiredField = this.requiredFields.find((x) => x.requiredField == RequiredField[RequiredField.IdentificationNumber]);
            return requiredField?.value;
        },
        getIbanDynamicMask() {
            switch (this.country) {
                case CountryCode[CountryCode.AT]:
                    return "AT## #### #### #### ####";
                case CountryCode[CountryCode.BE]:
                    return "BE## #### #### ####";
                case CountryCode[CountryCode.CH]:
                    return "CH## #### #@@@ @@@@ @@@@ @";
                case CountryCode[CountryCode.DE]:
                    return "DE## #### #### #### #### ##";
                case CountryCode[CountryCode.DK]:
                    return "DK## #### #### #### ##";
                case CountryCode[CountryCode.FI]:
                    return "FI## #### #### #### ##";
                case CountryCode[CountryCode.NL]:
                    return "NL## @@@@ #### #### ##";
                case CountryCode[CountryCode.NO]:
                    return "NO## #### #### ####";
                case CountryCode[CountryCode.SE]:
                    return "SE## #### #### #### #### ####";
                default:
                    return "SS## @@@@ #### #### ##";
            }
        },
        getIbanDynamicPlaceholder() {
            switch (this.country) {
                case CountryCode[CountryCode.AT]:
                    return "AT00 0000 0000 0000 0000";
                case CountryCode[CountryCode.BE]:
                    return "BE00 0000 0000 0000";
                case CountryCode[CountryCode.CH]:
                    return "CH00 0000 0XXX XXXX XXXX X";
                case CountryCode[CountryCode.DE]:
                    return "DE00 0000 0000 0000 0000 00";
                case CountryCode[CountryCode.DK]:
                    return "DK00 0000 0000 0000 00";
                case CountryCode[CountryCode.FI]:
                    return "FI00 0000 0000 0000 00";
                case CountryCode[CountryCode.NL]:
                    return "NL00 XXXX 0000 0000 00";
                case CountryCode[CountryCode.NO]:
                    return "NO00 0000 0000 0000";
                case CountryCode[CountryCode.SE]:
                    return "SE00 0000 0000 0000 0000 0000";
                default:
                    return "XX00 XXXX 0000 0000 00";
            }
        },
        async profileTracking() {
            if (this.country == CountryCode[CountryCode.AT] || this.country == CountryCode[CountryCode.DE]) {
                if (this.profileTrackingId.length > 0) {
                    this.sessionId = sessionStorage.getItem("sessionId") ?? "";
                    if (this.sessionId.length == 0) {
                        this.sessionId = uuidv4();
                        sessionStorage.setItem("sessionId", this.sessionId);
                    }

                    const config = new ProfileTrackingIdConfig(this.profileTrackingId, this.sessionId, window.location.pathname);
                    window._itt = config;

                    const script = document.createElement("script");
                    script.src = `https://${config.h}/container/${config.c}?page=${config.u}`;
                    script.async = true;
                    script.onload = function () {
                        (this as HTMLElement).remove();
                    };
                    (document.head || document.documentElement).appendChild(script);
                } else {
                    window.location.href = "/error";
                }
            }
        },
        getIpAddress() {
            fetch("https://api.ipify.org?format=json")
                .then((x) => x.json())
                .then(({ ip }) => {
                    this.ipAddress = ip;
                });
        },
        getOrSetIdempotencyKey() {
            const newKey = uuidv4();
            localStorage.setItem(`idempotencyKey-${this.orderKey}`, newKey);
            return newKey;
        },
        getOrSetRetryAttemptCount() {
            const newCount = "0";
            localStorage.setItem(`retryAttemptCount-${this.orderKey}`, newCount);
            return newCount;
        },
        verifyRequiredFields() {
            if (this.retryAttemptCount == 0) {
                this.oldRequiredFields = JSON.parse(JSON.stringify(this.requiredFields));
            }
        },
        verifyRetry() {
            this.retryAttemptCount++;

            if (JSON.stringify(this.requiredFields) != JSON.stringify(this.oldRequiredFields)) {
                this.idempotencyKey = uuidv4();
                this.retryAttemptCount = 0;
                localStorage.clear();
                localStorage.setItem(`idempotencyKey-${this.orderKey}`, this.idempotencyKey);
            }

            localStorage.setItem(`retryAttemptCount-${this.orderKey}`, `${this.retryAttemptCount}`);
            this.canContinue = true;

            this.$emit("onIdempotencyKeyUpdate", this.idempotencyKey);
            this.$emit("onRetryAttemptCountUpdate", this.retryAttemptCount);
            this.$emit("onCanContinueUpdate", this.canContinue);
        },
    },
});
