import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "row bg-white mt-3 mt-lg-0 px-2 py-4" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "r-font-size-500 mb-3" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-auto" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "col ps-0 pt-1" }
const _hoisted_9 = { class: "r-font-size-400 r-font-weight-semibold" }
const _hoisted_10 = { class: "r-font-size-300" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PayInXComponent = _resolveComponent("PayInXComponent")!
  const _component_FixedInstallmentsComponent = _resolveComponent("FixedInstallmentsComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.getTranslation("PaymentTitle")), 1),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("div", _hoisted_6, [
            _createElementVNode("img", {
              height: "56",
              width: "90",
              src: _ctx.logo,
              alt: "Riverty`s logo"
            }, null, 8, _hoisted_7)
          ], 512), [
            [_vShow, _ctx.logo]
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.paymentMethod.title), 1),
            _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.paymentMethod.method), 1)
          ])
        ]),
        (_ctx.paymentMethod.additionalData && _ctx.paymentMethod.type == _ctx.payInXType())
          ? (_openBlock(), _createBlock(_component_PayInXComponent, {
              key: 0,
              payInX: _ctx.getPayInXObj(),
              getTranslation: _ctx.getTranslation
            }, null, 8, ["payInX", "getTranslation"]))
          : _createCommentVNode("", true),
        (_ctx.paymentMethod.additionalData && _ctx.paymentMethod.type == _ctx.installmentType())
          ? (_openBlock(), _createBlock(_component_FixedInstallmentsComponent, {
              key: 1,
              ref: "fixedInstallmentsComponent",
              paymentMethod: _ctx.paymentMethod,
              getTranslation: _ctx.getTranslation
            }, null, 8, ["paymentMethod", "getTranslation"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}