<script lang="ts" src="./script.ts" />

<template>
    <div class="row">
        <div class="row bg-white mt-3 mt-lg-0 px-2 py-4">
            <div class="col-12">
                <p class="r-font-size-500 mb-3">{{ getTranslation("PaymentTitle") }}</p>
                <div class="row">
                    <div class="col-auto" v-show="logo">
                        <img height="56" width="90" :src="logo" alt="Riverty`s logo" />
                    </div>
                    <div class="col ps-0 pt-1">
                        <p class="r-font-size-400 r-font-weight-semibold">{{ paymentMethod.title }}</p>
                        <p class="r-font-size-300">{{ paymentMethod.method }}</p>
                    </div>
                </div>
                <PayInXComponent v-if="paymentMethod.additionalData && paymentMethod.type == payInXType()" :payInX="getPayInXObj()" :getTranslation="getTranslation" />
                <FixedInstallmentsComponent v-if="paymentMethod.additionalData && paymentMethod.type == installmentType()" ref="fixedInstallmentsComponent" :paymentMethod="paymentMethod" :getTranslation="getTranslation"></FixedInstallmentsComponent>
            </div>
        </div>
    </div>
</template>
