<style scoped lang="scss" src="./style.scss" />
<script lang="ts" src="./script.ts" />

<template>
    <div class="page">
        <div class="container">
            <div class="row ps-lg-5 my-5">
                <div class="col-auto ps-lg-5 ms-lg-5 mt-lg-3 mb-lg-5">
                    <img class="ps-lg-2" alt="Riverty logo" width="213" src="https://cdn.riverty.design/logo/riverty-logo-green.svg" />
                </div>
            </div>
            <div class="row mt-lg-4 pt-lg-5">
                <div class="col-12 col-lg-2">
                    <img alt="Error icon" class="float-lg-end" width="58" height="58" src="@/assets/exclamation_mark.svg" />
                </div>
                <div class="col-12 col-lg-10 max-width">
                    <h1 class="r-font-family-heading r-font-size-700 r-font-weight-light my-3 my-lg-0" v-html="getTranslation('TechnicalErrorTitle')"></h1>
                    <p class="r-font-size-400 my-lg-3 mb-5 mb-lg-4" v-html="getTranslation('TechnicalErrorDescription')"></p>
                    <r-button variant="primary" class="mt-lg-5" v-on:click="handleReturnTo">
                        <span>{{ getTranslation("ReturnTo", ["Riverty"]) }}</span>
                    </r-button>
                </div>
            </div>
        </div>
    </div>
</template>
