<style scoped lang="scss" src="./style.scss" />
<script lang="ts" src="./script.ts" />

<template>
    <div class="page">
        <header>
            <div class="container">
                <div class="row px-2 px-sm-0">
                    <div class="col-12 px-sm-0 py-4">
                        <img class="r-icon-size-l mb-2" alt="Riverty logo" src="https://cdn.riverty.design/logo/riverty-logomark-green.svg" />
                        <p class="r-font-family-heading r-font-size-600 r-font-weight-light mt-2">{{ getTranslation("CheckoutTitle") }}</p>
                    </div>
                </div>
            </div>
        </header>
        <main>
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg me-lg-3 order-2 order-lg-1">
                        <div class="row bg-white px-2 py-4">
                            <div class="col-12 mb-3">
                                <p class="r-font-size-500 mb-3">{{ getTranslation("SelectPaymentMethod") }}</p>
                            </div>
                            <div v-for="(item, index) in data.legalInfoRequiredFieldsPerPayments" v-bind:key="index" class="mb-4">
                                <r-radio-button :value="item.legalInfo.title" name="paymentMethods" v-on:click="onChecked(item)">
                                    <p class="r-font-weight-semibold">{{ item.legalInfo.title }}</p>
                                    <p>{{ item.legalInfo.tag }}</p>
                                </r-radio-button>
                            </div>
                            <div class="col-12 text-center text-lg-start">
                                <r-button variant="text" href="#modal-content" v-on:click="handleCancel">
                                    {{ getTranslation("CancelPayment") }}
                                </r-button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 order-1 order-lg-2">
                        <div class="row bg-white px-2 py-4">
                            <div class="col-12">
                                <p class="r-font-size-600 r-font-weight-semibold mb-4">
                                    {{ data.orderDetails.merchantName }}
                                </p>
                                <p class="r-font-size-600 r-font-weight-semibold">
                                    {{ currency(data.orderDetails.orderDetail.totalAmount) }}
                                </p>
                                <r-accordion>
                                    <r-accordion-item :header="getTranslation('OrderDetailsTitle')">
                                        <div class="row" v-for="(item, index) in data.orderDetails.orderDetail.items" v-bind:key="index">
                                            <div class="col-12">
                                                <div class="row h-100 align-items-center">
                                                    <div class="col">
                                                        <p>
                                                            {{ item.quantity }}x
                                                            {{ item.name }}
                                                        </p>
                                                        <p>
                                                            {{ item.description }}
                                                        </p>
                                                    </div>
                                                    <div class="col-auto">
                                                        <p>
                                                            {{ currency(item.grossUnitPrice) }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr class="m-0 pb-4" />
                                            </div>
                                        </div>
                                        <div v-if="showAddresses()" class="row mt-3">
                                            <div class="col-12">
                                                <p class="r-font-weight-semibold">{{ getTranslation("ShippingAddress") }}</p>
                                                <p>{{ data.orderDetails.shippingCustomer.firstName }} {{ data.orderDetails.shippingCustomer.lastName }}</p>
                                                <p>{{ data.orderDetails.shippingCustomer.address.street }} {{ data.orderDetails.shippingCustomer.address.streetNumber }}</p>
                                                <p>{{ data.orderDetails.shippingCustomer.address.postalCode }} {{ data.orderDetails.shippingCustomer.address.countryCode }}</p>
                                                <p>{{ data.orderDetails.shippingCustomer.address.postalPlace }}</p>
                                            </div>
                                            <div class="col-12 mt-3">
                                                <p class="r-font-weight-semibold">{{ getTranslation("BillingAddress") }}</p>
                                                <p>{{ data.orderDetails.billingCustomer.firstName }} {{ data.orderDetails.billingCustomer.lastName }}</p>
                                                <p>{{ data.orderDetails.billingCustomer.address.street }} {{ data.orderDetails.billingCustomer.address.streetNumber }}</p>
                                                <p>{{ data.orderDetails.billingCustomer.address.postalCode }} {{ data.orderDetails.billingCustomer.address.countryCode }}</p>
                                                <p>{{ data.orderDetails.billingCustomer.address.postalPlace }}</p>
                                            </div>
                                        </div>
                                    </r-accordion-item>
                                </r-accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <footer>
            <div class="container">
                <div class="row px-2 px-sm-0">
                    <div class="col-12 px-sm-0 pb-3 pt-5 mt-5 pt-lg-3 mt-lg-0">
                        <p class="r-font-size-200">{{ getTranslation("Powered") }}</p>
                        <img alt="Riverty logo" width="143" src="https://cdn.riverty.design/logo/riverty-logo-green.svg" />
                    </div>
                </div>
            </div>
        </footer>
    </div>
    <ModalComponent>
        <div v-if="!showErrorPaymentMessage" class="container p-0">
            <div class="row pb-3">
                <div class="col-12">
                    <p class="r-font-family-heading r-font-size-600 r-font-weight-light mt-3 mb-4">{{ getTranslation("TitleCancelation") }}</p>
                    <p class="r-font-size-400 mb-4 pb-3" v-html="getTranslation('DescriptionCancelation', [data.orderDetails.merchantName])"></p>
                </div>
                <div class="col-12 text-center text-lg-start col-lg-auto">
                    <r-button variant="primary" class="w-100 mb-2" v-on:click="handleConfirmCancelation">
                        {{ getTranslation("ConfirmCancelation") }}
                    </r-button>
                    <br />
                    <r-button variant="text" v-on:click="handleContinueWith">
                        {{ getTranslation("ContinueWith") }}
                    </r-button>
                </div>
            </div>
        </div>
        <div v-else class="container p-0">
            <div class="row pb-3">
                <div class="col-12">
                    <p class="r-font-family-heading r-font-size-600 r-font-weight-light my-4">{{ getTranslation("PaymentCustomErrorTitle") }}</p>
                    <p class="r-font-size-400 mb-4 pb-3" v-html="getTranslation('PaymentCustomErrorSubTitle', [''])"></p>

                    <r-button variant="primary" v-on:click="handleContinueWith">
                        {{ getTranslation("ReturnTo", [data.orderDetails.merchantName ?? getTranslation("GenericReturnToMerchant")]) }}
                    </r-button>
                </div>
            </div>
        </div>
    </ModalComponent>
</template>
